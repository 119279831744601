import * as React from 'react'
import BlogPostOld from "../../components/blog-post-old";
import image from "../../images/blog29/image1.png"
import image2 from "../../images/blog29/image2.jpg"





export default function TheKeyToSuccessfulTraining(){
    return(
        <BlogPostOld
            src={image2}
            title={"The Key to Successful Training"}
            summary={"In my opinion Training is a major pillar in healthcare. Training promotes long term health while staving off chronic illnesses such as heart disease, diabetes, stroke and even some cancers. Considering these are some of the leading causes of disability and healthcare costs - I’d say learning how to become and stay physically fit is CRUCIAL to healthcare."}
            date={"Jan. 9, 2023"}
            author={"Dr. Donald Mull, DC"}
            InStyle={"max-h-full"}

        >
            <div>
                <p>In my opinion Training is a major pillar in healthcare. Training promotes long term health while staving off chronic illnesses such as heart disease, diabetes, stroke and even some cancers. Considering these are some of the leading causes of disability and healthcare costs - I’d say learning how to become and stay physically fit is CRUCIAL to healthcare.</p>
                <p>However, it can be overwhelming to understand how to put together a plan that is sustainable. There are millions of videos online that say this form is good and that form is bad. Plenty of programs insist that if you follow this step by step you will achieve every fitness goal you could ever dream of.&nbsp;</p>
                <p>To put it nicely, they are all over promising.&nbsp;</p>
                <p className={"text-center"}><strong><em>The perfect exercise and/or program does not exist. If they did, we wouldn’t have a pandemic of inactivity riddled with chronic disease.&nbsp;</em></strong></p>
                <p>Training is a constant moving target. All lifestyle factors like stress, sleep, nutrition, training experience and more come into play when training. All of these things can be considered a stimulus which affects a training outcome.&nbsp;</p>
                <p>A stimulus to any organism drives a response. To simplify things we are going to categorize this response into either a fitness response (positive training outcomes) or a fatigue response (negative training outcomes). Let’s take a look at the flowchart below and I will walk you through how we help people get better outcomes with their training (also how we help people overcome pain).</p>
                <img src={image} alt={"diagram"}/>
                <p>When training the body, think of this as a stimulus to the body. It is not good or bad without context. Remember, it is only one of many stimuli placed on the body throughout a given time period. Just like physical activity is a stimulus, so is what we eat, the drugs we take, sunlight we take in, amount of sleep we get and so on.&nbsp;</p>
                <p>If we neglect the things that recharge our bodies, no program in the world is going to get the most out of your body (these things are not separate). A good night’s sleep, balanced nutritional consumption paired with physical activity that is tolerable (not too hard - not too easy) is a great formula to see a positive fitness response.&nbsp;</p>
                <p>Keep in mind, this is ALWAYS a moving target. No one is perfect. We all have bad days where maybe we overindulge, get bad sleep, lose a loved one, the list goes one. This is called life. If our training does not adapt to these factors that drive a fatigue response then we will just add more fatigue on top of fatigue.&nbsp;</p>
                <p>To achieve the positive responses we are after, how much of the activity is of the most importance to me. I want to find the maximum amount of activity your body can tolerate. This takes some trial and error.&nbsp;</p>
                <p>Along this process, your body will leave some hints and it is our job to follow those breadcrumbs that indicate positive results (better sleep, increased mood, increased confidence, refined skills, muscle soreness in targeted area, actual decrease in any nagging painful areas, etc). In doing this we must also keep our eyes peeled for signs of fatigue (poor sleep, increased irritability, increased fear of movements, irritation of any nagging injuries, etc).&nbsp;</p>
                <p>Good training is learning how to spot signs of fitness as well as signs of fatigue and knowing what to do once you spot them. This is why I think it can be very beneficial to work with a coach. Is it possible to be healthy and fit without one? Absolutely! But a good coach or clinician should help streamline this process.</p>
                <p>In summary, there are no perfect exercises nor is there one size fits all programs. If you want to be healthy, start with things you CAN do and build the habits and confidence from there. Once you have a head of steam going, start to make things harder and challenge yourself. Along this journey your body will give you hints of both fitness as well as fatigue. There will be good days and there will be bad days - no workout will be perfect (even for the fittest people in the world).&nbsp;</p>
                <h2 className={"text-3xl text-center pt-8"}>Come train with us!!!</h2>
                <div className={"hidden lg:block"}>

                    <table>
                        <tbody>



                        <tr >
                            <td className={"p-4 text-xl text-center"}><a href="https://kirehab.com/one-on-one-coaching" target="_blank" rel="noopener">1-on-1 Personal Training</a></td>
                            <td className={"p-4 text-xl text-center"}><a href="https://kirehab.com/small-group-training" target="_blank" rel="noopener">Semi-Private Training</a></td>
                            <td className={"p-4 text-xl text-center"}><a href="https://kirehab.com/adult-group-class" target="_blank" rel="noopener">Adult Group Classes</a></td>
                            <td className={"p-4 text-xl text-center"}><a href="https://kirehab.com/youth-group-class" target="_blank" rel="noopener">Youth Group Classes</a></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className={"block lg:hidden"}>

                    <table className={"mx-auto"}>
                        <tbody >



                        <tr>
                            <td className={"p-4 text-xl text-center"}><a href="https://kirehab.com/one-on-one-coaching" target="_blank" rel="noopener">1-on-1 Personal Training</a></td>
                        </tr><tr>
                            <td className={"p-4 text-xl text-center"}><a href="https://kirehab.com/small-group-training" target="_blank" rel="noopener">Semi-Private Training</a></td>
                        </tr><tr>
                            <td className={"p-4 text-xl text-center"}><a href="https://kirehab.com/adult-group-class" target="_blank" rel="noopener">Adult Group Classes</a></td>
                        </tr><tr>
                            <td className={"p-4 text-xl text-center"}><a href="https://kirehab.com/youth-group-class" target="_blank" rel="noopener">Youth Group Classes</a></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </BlogPostOld>

    )
}